import store from './store';

const install = app => {
    if (install.installed)
        return;
    install.installed = true;
    app.config.globalProperties.$store =  store
};

export default install
