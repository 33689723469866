import { createStore } from 'vuex'

import localStorageUtil from "@/utils/localStorageUtil";
import * as taskApi from "@/api/moudules/taskApi";

const SET_RESUME_COUNT = "setResumeCount"

const positionHroResumeCountStore = createStore({
    state () {
        return {
            resumeCount:{
                totalCount: 0,
                entTotalCount: 0,
                recommendNum: 0,
                recommendToIn: 0,
                recommendToEnt: 0,
                interView: 0,
                enter: 0,
                noSuitable: 0,
                entryOutNum: 0,
                departCount: 0,
            }
        }
    },
    mutations: {
        [SET_RESUME_COUNT] (state,data) {
            state.resumeCount = {
                totalCount: data.totalNum,
                recommendNum: data.recommendNum,
                recommendToIn: data.recommendInternalNum,
                recommendToEnt: data.recommendEntNum,
                interView: data.interviewNum,
                enter: data.entryNum,
                departNum: data.dimissionNum,
                noSuitable: data.inappropriateNum,
                entryOutNum: data.entryOutNum,
                entTotalCount: data.recommendEntNum + data.interviewNum + data.entryNum +  data.inappropriateNum,
                departCount:data.dimissionNum
            }

        },
    },
    actions:{
        refreshResumeCount(context,data){
            let params = {
                orgCode: localStorageUtil.get("orgCode"),
                userId: localStorageUtil.get("userId"),
                positionHroId: data.positionHroId,
                visitorType: data.visitorType
            }

            taskApi.getResumeNumByHroId(params).then(res => {
                context.commit(SET_RESUME_COUNT, res.data);
            })
        }
    }
})

export default positionHroResumeCountStore