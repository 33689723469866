import { createStore } from 'vuex'

import localStorageUtil from "@/utils/localStorageUtil";
import api from "@/api/api";

const defaultStore = createStore({
    state () {
        return {
            messageTotalCount: "",
            prevFreshTime: 0,
            headUserInfo:{
                avatar: "",
                name: "",
                orgName: ""
            },
            email: '',
        }
    },
    mutations: {
        loadMessageCount (state) {

            let isFresh = false;
            let nowTime = Date.parse(new Date());

            if(state.messageTotalCount == 0){
                isFresh = true;
            }else{
                if(state.prevFreshTime == 0 ){
                    isFresh = true;
                    state.prevFreshTime = nowTime;
                }
                else{
                    let c = nowTime - state.prevFreshTime;

                    if(c > 1000){
                        isFresh = true;
                        state.prevFreshTime = nowTime;

                    }
                }
            }

            if(isFresh){
                var params = {
                    orgCode: localStorageUtil.get("orgCode"),
                    userId: localStorageUtil.get("userId"),
                    isLoading: 0
                }
                api.messageApi.getMessageCount(params)
                    .then( res => {
                        state.messageTotalCount = res.data;

                        if(state.messageTotalCount == 0){
                            state.messageTotalCount = "";
                        }
                    })
            }



        },
        setMessageCount(state,count){
            state.messageTotalCount = count;
        },
        clearLocalStorage(){
            localStorageUtil.remove("userId");
            localStorageUtil.remove("token");
            localStorageUtil.remove("userInfo");
        },

        loadUserInfo(state){
        
            let userInfo = localStorageUtil.get("userInfo");

            
            state.headUserInfo.avatar = userInfo.avatar;
            state.headUserInfo.name = userInfo.name;
            state.headUserInfo.orgLogo = userInfo.orgLogo;
            state.headUserInfo.orgName = userInfo.orgName;
        },

        editHeaderUserorgLogo(state,avatar){
            state.headUserInfo.entLogo = avatar;

            let entInfo = localStorageUtil.get("orgLogo");
            entInfo = avatar;

            localStorageUtil.set('orgLogo', entInfo);
        },

        editHeaderUserAvatar(state,avatar){

            state.headUserInfo.avatar = avatar.avatar;
            state.headUserInfo.name = avatar.name;
            // console.log(state,avatar, 'Vuex')

            let userInfo = localStorageUtil.get("userInfo")
            userInfo.avatar = avatar.avatar;
            userInfo.name = avatar.name;
            localStorageUtil.set("userInfo",userInfo);
        },

        editEmail(state,avatar){
            state.email = avatar;

            let userInfo = localStorageUtil.get("userInfo")
            userInfo.email = avatar;
            localStorageUtil.set("userInfo",userInfo);
        },

        editHeaderUser(state,userInfo){
            state.headUserInfo.avatar = userInfo.avatar;
            state.headUserInfo.name = userInfo.name;

            let userInfoStore = localStorageUtil.get("userInfo")
            userInfoStore.avatar = userInfo.avatar;
            userInfoStore.name = userInfo.name;
            localStorageUtil.set("userInfo",userInfoStore);
        },
        editOrgInfo(state,orgInfo){
            state.headUserInfo.orgLogo = orgInfo.orgLogo;
            state.headUserInfo.orgName = orgInfo.orgName;

            let userInfoStore = localStorageUtil.get("userInfo");
            userInfoStore.orgLogo = orgInfo.orgLogo;
            userInfoStore.orgName = orgInfo.orgName;

            localStorageUtil.set('userInfo', userInfoStore);
        },
    }
})

export default defaultStore