import axios from "../axios";

// 获取企业信息
export const get_ent_info = (params) => {
    return axios('get', '/api/enterprise/get_ent_info', params);
}

// 修改企业信息
export const edit_ent_info = (params) => {
    return axios('post', '/api/enterprise/edit_ent_info', params);
}

// 获取企业邀请员工二维码
export const createQRCode = (params) => {
    return axios('get', '/api/enterprise/createQRCode', params);
}

// 获取人才认定流程申请记录
export const get_flow_apply_list = (params) => {
    return axios('get', '/api/enterprise/get_flow_apply_list', params);
}