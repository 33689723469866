import { createStore } from 'vuex'

import localStorageUtil from "@/utils/localStorageUtil";
import api from "@/api/api";

const accountStore = createStore({
    state() {
        return{
            params: ''
        }
    },
    mutations: {
        updateParam(state, value) {

            state.params = value;

            localStorageUtil.set("activeType",value);
        }
    }
})

export default accountStore