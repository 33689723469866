import qs from 'qs'


let baseURL = process.env.VUE_APP_BASE_URL

export default {
    method: 'get',
    // 基础url前缀
    baseURL: baseURL,//请求的域名地址
    // 请求头信息
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    // 参数
    data: {},
    // 设置超时时间
    timeout: 30000,
    // 携带凭证
    withCredentials: false,
    // 返回数据类型
    responseType: 'json',
    // `paramsSerializer` 是一个负责 `params` 序列化的函数
    paramsSerializer: function(params) {
        return qs.stringify(params)
    }
}
