import common from './common'

const filters = {
    getDateShowText: function(value,format) {
        if(common.isEmpty(value)){
            return "";
        }

        return common.formatDate(value,format);
    },
    getResumeDate: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"yyyy-MM-dd");
    },
    getDate: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"yyyy-MM-dd hh:mm");
    },
    getJobTypeName: function (value) {

        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "到面";
            case 2:
                return "入职";
            case 3:
                return "RPO";
        }
    },
    getSexText: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "男";
            case 2:
                return "女";
            case 3:
                return "其他";
            default:
                return "";
        }
    },
    getLaborNum: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return '0'
            case 1:
                return "1-50";
            case 2:
                return "50-90";
            case 3:
                return "100-199";
            case 4:
                return "200-499";
            case 5:
                return "500-999";
            case 6:
                return "1000及以上";
            default:
                return "";
        }
    },
    getLbbStatusText: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case -1:
                return "未申请";
            case 0:
                return "待开通";
            case 1:
                return "已开通";
            case 2:
                return "已驳回";
            case 3:
                return "已下线";
            case 10:
                return "已删除";
            default:
                return "";
        }
    },
    getLbbReasonText: function(value,reason){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "证件审核未通过";
            case 2:
                return "证件图片模糊";
            case 3:
                return "证件信息与填写信息不一致";
            case 4:
                return "不属于劳务顾问";
            case 5:
                return reason == null || reason == "" ? "其他": reason;
            default:
                return "";
        }
    },

    cityPopFilterSuffix2(city){
        
        if(common.isEmpty(city)){
            return "";
        }
        let name = ""

        if(city.parentId == '0'){
            name = city.cityName;

            name = name.replace('省','');
            name = name.replace('市','');
            name = name.replace('壮族自治区','');
            name = name.replace('回族自治区','');
            name = name.replace('维吾尔自治区','');
            name = name.replace('自治区','');
        }
        else{
            name = city.cityName;
        }
        return name;
    },

    getlastStage(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "审核";
            case 10:
                return "初筛";
            case 30:
                return "面试";
            case 40:
                return "入职";
            case 50:
                return "不合适";
            default:
                return "";

        }
    },

    getinappropriate: function (value){
        switch (value){
            case 1:
                return "未参加面试";
            case 2:
                return "条件不匹配";
            case 3:
                return "专业知识不匹配";
            case 4:
                return "薪资要求不匹配";
            case 5:
                return "行业背景不匹配";
            case 6:
                return "其它";
            default:
                return "";
        }
    },

    dimissionType: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "不适应公司文化";
            case 2:
                return "企业内部结构调整";
            case 3:
                return "合同期满，未续约";
            case 4:
                return "项目结束，无用工需求";
            case 5:
                return "其他原因";
            case 6:
                return "个人原因";
            case 7:
                return "薪酬待遇低";
            case 8:
                return "缺少发展空间";
        }
    },

    sendStatusType: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        switch (value) {
            case 1:
                return "自主投递"
            case 2:
                return "经纪人推荐"
            case 3:
                return "内部推荐"
            case 4:
                return "服务机构推荐"
            default:
                return "";
        }
    },
     
    getFeebackType: function (value) {
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "24H";
            case 2:
                return "48H";
            case 3:
                return "72H";
        }
    },
    getPositionCode: function (value) {
        if(common.isEmpty(value)){
            return "";
        }

        let val = value + "";

        let str = '000000' + val;


        return str.substr(val.length,6)
    },
    getEducation: function (value) {
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "不限";
            case 1:
                return "高中";
            case 2:
                return "中专";
            case 3:
                return "大专";
            case 4:
                return "本科";
            case 5:
                return "硕士";
            case 6:
                return "博士";
            case 7:
                return "MBA/EMBA";
            case 8:
                return "博士后";
        }
    },
    getResumeEducation:function(value){
        if(common.isEmpty(value)){
            return "";
        }
        switch (value) {
            case -3:
                return "小学";
            case -2:
                return"初中";
            case -1:
                return "中专";
            case 0:
                return "高中";
            case 8:
                return"专科";
            case 16:
                return "本科";
            case 24:
                return "硕士、专业学位硕士";
            case 28:
                return "学术型硕士";
            case 29:
                return "MBA";
            case 30:
                return "EMBA";
                case 32:
                return "博士、专业学位博士";
            case 36:
                return "学术型博士";
            case 40:
                return "博士后";
        }
    },
    getRecruitmentText(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "非统招";
            case 1:
                return "统招";
            default:
                return "";

        }
    },

    getPersonnelTypeValue(value,defaultVal){
        if(common.isEmpty(value)){
            return defaultVal;
        }
        else{
            switch (value){
                case 1:
                    return "边缘户";
                case 2:
                    return "腿部渔民";
                case 3:
                    return "脱贫劳动力";
                case 4:
                    return "异地搬迁脱贫劳动力";
                case 5:
                    return "高校毕业生";
                case 6:
                    return "农村转移就业劳动力";
                case 7:
                    return "退伍军人";
                case 8:
                    return "城镇结业困难户";
                case 9:
                    return "残疾人员";
                case 10:
                    return "低保户";
                case 11:
                    return "戒毒康复人员";
                case 12:
                    return "刑满释放人员";
                case 13:
                    return "其他";
                default:
                    return "";
            }
        }
    },

    getPoorFamilyType(value,defaultVal){
        if(common.isEmpty(value)){
            return defaultVal;
        }else{

            switch (value){
                case 1:
                    return "是";
                case 2:
                    return "否";
                default:
                    return "";
    
            }
        }

    },

    getJobText: function (value, defaultVal){
        if(common.isEmpty(value)){
            return defaultVal;
        }else{

            switch (value){
                case 0:
                    return "否";
                case 1:
                    return "是";
                default:
                    return "";
            }
        }
    },

    getJobAreaText: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (Number(value) ){
            case 1:
                return "区内就业";
            case 2:
                return "区外就业";
            case 3:
                return "市外就业";
            case 4:
                return "省外就业";
            case 5:
                return "国外就业";
            default:
                return "";
        }
    },

    getJobNatureText: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "全职";
            case 1:
                return "兼职";
            case 2:
                return "临时工";
            default:
                return "";
        }
    },

    getJobStatusText: function(value){
        switch (value){
            case 0:
                return "失业";
            case 1:
                return "本地就业"
            case 2:
                return "异地就业";
            default:
                return "";
        }
    },
    
    getJobStatus: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "失业";
            case 1:
                return "就业";
            default:
                return "";
        }
    },

    getResumeTimeDate: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"yyyy.MM.dd");
    },

    getTimeDate: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"hh:mm:ss");
    },

    getVehicleType: function (value) {
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "未知";
            case 1:
                return "自驾";
            case 2:
                return "包车";
            case 3:
                return "火车";
            case 4:
                return "飞机";
            case 10:
                return "其他";
            default:
                return "";
        }
    },

    getReturnToWork: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "未返岗";
            case 1:
                return "已返岗";
            default:
                return "";
        }
    },

    roleType: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "外服";
            case 2:
                return "";
            case 3:
                return "劳务合作社";
            case 4:
                return "";
            default:
                return "";
        }
    },

    getGoOut: function (value, defaultVal){
        if(common.isEmpty(value)){
            return defaultVal;
        }else{

            switch (value){
                case 0:
                    return "无";
                case 1:
                    return "有";
                default:
                    return "";
            }
        }
    },

    geTimeDate: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"yyyy-MM-dd");
    },

    getJobStatusType: function (value, defaultVal){
        if(common.isEmpty(value)){
            return defaultVal;
        }else{

            switch (value){
                case 0:
                    return "失业";
                case 1:
                    return "就业";
                default:
                    return "";
            }
        }
    },

    getResumeDate1: function(value) {
        if(common.isEmpty(value)){
            return "";
        }

        if(value == -999999999999){
            return "至今";
        }

        return common.formatDate(value,"yyyy.MM.dd hh:mm");
    },
    
    getCategoryText(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "其他";
            case 1:
                return "985";
            case 2:
                return "211";
            default:
                return "";

        }
    },
    getPositionType(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "其它";
            case 1:
                return "到面";
            case 2:
                return "入职";
            case 3:
                return "RPO";
            default:
                return "";

        }
    },
    getScale(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "0-20人";
            case 2:
                return "20-50人";
            case 3:
                return "50-100人";
            case 4:
                return "100-500人";
            case 5:
                return "500人以上";
            default:
                return "";

        }
    },
    getStageState(value){
        switch (value){
            case 0:
                return "加入到项目";
            case 5:
                return "推荐待审核";
            case 15:
                return "审核驳回";
            case 30:
                return "待反馈";
            case 40:
                return "预约面试";
            case 50:
                return "待面试";
            case 55:
                return "拒绝面试";
            case 60:
                return "待面试"; //修改面试时间
            case 65:
                return "放弃面试";
            case 70: //顾问发起  -> hr确认后（候选人未确认），76 ->候选人确认后（企业未确认）75   -> 确认到场 90 | 候选人确认未到场 80
                return "到场确认中";
            case 75: // 候选人确认，等待企业确认
                return "等待企业确认";
            case 76: //企业确认 等待候选人确认
                return "等待候选人确认";
            case 77: //候选人确认未到场 等待企业确认
                return "等待企业确认";
            case 78: //企业确认未到场 等待候选人确认
                return "等待候选人确认";
            case 80: //到场  人工核实  (未发消息) 
                return "人工核实中";
            case 81: //到场  人工核实  （已发消息）
                return "人工核实中";
            case 90: //到场  
                return "已确认到场";
            case 91: //到场  
                return "已到场";
            case 100:
                return "人工核实已到场";
            case 110:
                return "不合适";
            case 120:
                return "未到场";
            case 130:
                return "撤销不合适";
            case 140:
                return "面试通过";
            case 150:
                return "面试不合适";
            case 160:
                return "发送offer";
            case 170:
                return "发送入职申请";
            case 180:
                return "未入职";
            case 190:
                return "确认入职";
            case 200:
                return "入职申诉中";
            case 210: //hr
                return "保内离职待确认";
            case 220: //顾问
                return "发起保内离职";
            case 230: //hr
                return "确认保内离职 ";
            case 240: //顾问
                return "确认保内离职 ";
            case 250: //hr
                return "人工核实中";
            case 260: //顾问
                return "人工核实中";
            case 270: //
                return "结算完成";
            case 280: //
                return "结算中";
            default:
                return "";
        }
    },
    getInvalidStage(value){
        switch (value){
            case 0:
                return "加入到项目";
            case 100:
                return "推荐待审核";
            case 200:
                return "推荐待反馈";
            case 250:
                return "约面中";
            case 300:
                return "待面试";
            case 400:
                return "确认到场";
            case 500:
                return "已到场";
            case 1000:
                return "无效候选人";
            case 1100:
                return "一面"
            case 1200:
                return "二面"
            case 1300:
                return "三面"
            case 1400:
                return "四面"
            case 1500:
                return "面试";
            case 1700:
                return "offer";
            case 1800:
                return "入职";
            case 1900:
                return "结算完成";
            default:
                return "";
        }
    },

    getInvalidStage1(value){
        switch (value){
            case 0:
                return "加入到项目";
            case 100:
                return "推荐待审核";
            case 200:
                return "推荐待反馈";
            case 250:
                return "约面中";
            case 300:
                return "待面试";
            case 400:
                return "确认到场";
            case 500:
                return "已到场";
            case 1000:
                return "无效候选人";
            case 1100:
                return "一面"
            case 1200:
                return "二面"
            case 1300:
                return "三面"
            case 1400:
                return "四面"
            case 1500:
                return "终面";
            case 1700:
                return "offer";
            case 1800:
                return "入职";
            case 1900:
                return "结算完成";
            default:
                return "";
        }
    },
    
    // 未填写
    getDefaultValue(value,defaultVal){
        if(common.isEmpty(value)){
            return defaultVal;
        }
        else{
            return value;
        }
    },
    getInterviewStage(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 250:
                return "约面中";
            case 1100:
                return "一面";
            case 1200:
                return "二面";
            case 1300:
                return "三面";
            case 1400:
                return "四面";
            case 1500:
                return "五面";
            default:
                return "";
        }
    },

    getInterviewStatus(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 40:
                return "待顾问确认";
            case 50:
                return "待面试";
            case 60:
                return "待面试";
            case 140:
                return "已面试";
            case 150:
                return "面试不合适";
            default:
                return "";
        }
    },
    getInterviewType(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "工作日都可以";
            case 2:
                return "其他时间，请与企业沟通";
            default:
                return "";
        }
    },
    getChildHadText(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "没有";
            case 1:
                return "有";
            default:
                return "没有";
        }
    },
    getChildAgeText(value){
        if(common.isEmpty(value)){
            return "";
        }

        return value + "岁"
    },
    showAddUnit(value,unit,defaultValue){
        if(common.isEmpty(value)){
            return defaultValue;
        }
        else{
            return value + unit;
        }
    },
    getSalaryYearText(value,defaultValue){
        if(common.isEmpty(value)){
            return defaultValue;
        }
        else{
            let wan = value/10000;
            var num = new Number(wan);

            return num.toFixed(1) + "W/年"
        }
    },
    getJoin(value,fg){
        if(value == null || value.length  == 0){
            return "";
        }

        return value.join(fg)
    },
    getListenSpeak(value){

        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "听说一般";
            case 2:
                return "听说良好";
            case 3:
                return "听说熟练";
            case 4:
                return "听说精通";
        }

    },
    getReadWrite(value){

        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "读写一般";
            case 2:
                return "读写良好";
            case 3:
                return "读写熟练";
            case 4:
                return "读写精通";
        }

    },
    getDimissionTypeText(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "企业淘汰";
            case 2:
                return "候选人放弃";
            default:
                return "";
        }
    },
    getDimissionReasonText(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 130:
                return "候选人能力有限";
            case 140:
                return "工作消极";
            case 150:
                return "无法融入新环境";
            case 160:
                return "与企业价值观不符";
            case 170:
                return "加班严重";
            case 180:
                return "公司氛围不喜欢";
            default:
                return "";
        }
    },
    //出差频率
    getEvectionFrequency(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "无需出差";
            case 2:
                return "偶尔出差";
            case 3:
                return "经常出差";
            case 4:
                return "常驻外省（市)";
            case 5:
                return "常驻海外";
            default:
                return "";
        }
    },
    //岗位产生原因
    getPositionProduceReason(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "扩编";
            case 2:
                return "新增岗位";
            case 3:
                return "前任离职";
            case 4:
                return "现任替换";
            default:
                return "";
        }
    },
    //已招聘多久
    getHowLong(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "刚刚开始";
            case 2:
                return "一个月以内";
            case 3:
                return "1-3月";
            case 4:
                return "三个月以上";
            default:
                return "";
        }
    },
    //工作年限
    getWorkingLife(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "不限";
            case 1:
                return "一年以下";
            case 2:
                return "1-3年";
            case 3:
                return "3-5年";
            case 4:
                return "5-10年";
            case 5:
                return "十年以上";
            default:
                return "";
        }
    },
    //性别限制
    getSex(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "男";
            case 2:
                return "女";
            case 3:
                return "不限";
            default:
                return "";
        }
    },

    getLanguage(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "英语";
            case 2:
                return "法语";
            case 3:
                return "日语";
            case 4:
                return "韩语";
            case 5:
                return "德语";
            case 6:
                return "俄语";
            case 7:
                return "西班牙语";
            case 8:
                return "葡萄牙语";
            case 9:
                return "阿拉伯语";
            case 10:
                return "普通话";
            case 11:
                return "粤语";
            case 999:
                return "其他";
            default:
                return "";
        }
    },

    getEnglishRequire(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "听说读写熟练";
            case 2:
                return "日常交流";
            case 3:
                return "可阅读资料文献";
            case 4:
                return "不限";
            default:
                return "";
        }
    },
    getLeadTeam(value){
        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "需要";
            case 2:
                return "不需要";
            default:
                return "";
        }
    },

    getgradeTypeData(value,defaultValue){
        if(common.isEmpty(value)){
            return defaultValue;
        }

        switch (value){
            case 0:
                return "未知";
            case 1:
                return "学徒工";
            case 5:
                return "初级工";
            case 10:
                return "中级工";
            case 15:
                return "高级工";
            case 20:
                return "技师";
            case 25:
                return "高级技师";
            case 30:
                return "特级技师";
            case 35:
                return "首席技师";
            default:
                return "";
        }
    },

    gethadSkillCertificate(value, defaultValue){
        if(common.isEmpty(value)){
            return defaultValue;
        }
        switch (value){
            case 0:
                return "未获得";
            case 1:
                return "已获得";
            default:
                return "";
        }
    },
    cityPopFilterSuffix(city){
        if(common.isEmpty(city)){
            return "";
        }
        let name = ""

        if(city.pcode == '0'){
            name = city.name;

            name = name.replace('省','');
            name = name.replace('市','');
            name = name.replace('壮族自治区','');
            name = name.replace('回族自治区','');
            name = name.replace('维吾尔自治区','');
            name = name.replace('自治区','');
        }
        else{
            name = city.name;
        }
        return name;
    },
    getNatureText: function (value){
        let natureArr = [
            "其他",
            "外商独资",
            "中外合资",
            "民营企业",
            "国有企业",
            "国内上市企业",
            "政府机关/非营利机构",
            "事业单位",
        ]
        let code = -1;
        if(typeof value =='string'){
            try {
                code = parseInt(value)
            }
            catch (e) {
                console.log(e)
            }
        }
        else if(typeof value == 'number'){
            code = parseInt(value);
        }
        else{
            return "";
        }

        return natureArr[code];
    },
    getNatureText1: function (value) {

        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 0:
                return "其他";
            case 1:
                return "外商独资";
            case 2:
                return "中外合资";
            case 3:
                return "民营企业";
            case 4:
                return "国有企业";
            case 5:
                return "国内上市企业";
            case 6:
                return "政府机关/非营利机构";
            case 7:
                return "事业单位";
            default:
                return "";
        }
    },
    getScaleText: function (value) {

        if(common.isEmpty(value)){
            return "";
        }

        switch (value){
            case 1:
                return "0-20人";
            case 2:
                return "20-50人";
            case 3:
                return "50-100人";
            case 4:
                return "100-500人";
            case 5:
                return "500人以上";
            default:
                return "";
        }
    },
    getMoneyText: function (value){
        if(common.isEmpty(value)){
            return "";
        }

        let str = value.toString();

        if(str.indexOf(".00")){
            str = str.replace(".00","");
        }
        return str;
    },
    subString: function (value,len) {

        if(value && value.length > len){
            var short_string = value.substr(0,len- 1);

            return short_string + "...";
        }else{
            return value;
        }
    },
    getResumeOperateStateText: function (value) {

        switch (value){
            case 5:
                return "创建简历";
            case 0:
                return "加入职位";
            case 100:
                return "推荐待审核";
            case 105:
                return "移出简历";
            case 110:
                return "待企业反馈";
            case 115:
                return "简历审核驳回";
            case 120:
                return "重新推荐简历";
            case 1000:
                return "添加到无效候选人";
            case 10:
                return "简历已读";
            // case 10:
            //     return "推荐简历";
            case 250:
                return "发起面试";
            case 255:
                return "简历不合适";
            case 50:
                return "确认面试";
            case 60:
                return "修改面试时间";
            case 65:
                return "放弃面试";
            case 70:
                return "发起到场确认";
            case 75:
                return "等待企业确认";
            case 76:
                return "等待候选人确认";
            case 80:
                return "无效候选人";
            case 85:
                return "申诉中";
            case 90:
                return "结算完成";
            case 95:
                return "添加面试反馈";
            case 125:
                return "已发offer";
            case 130:
                return "发起入职";
            case 135:
                return "发起未入职";
            case 140:
                return "已确认入职";
            case 145:
                return "发起入职申诉";
            case 150:
                return "发起保内离职";
            case 155:
                return "确认保内离职";
            case 160:
                return "发起保内离职申诉";
            case 165:
                return "入职过保";
            default:
                return "";
        }
    },


    
    getHroResumeOperateStateText: function (value){
        switch (value){
            case 1:
                return "推荐内服";
            case 2:
                return "推荐企业";
            case 3:
                return "面试";
            case 4:
                return "面试修改";
            case 5:
                return "入职";
            case 6:
                return "不合适";
            case 7:
                return "撤回不合适";
            case 8:
                return "离职";
            default:
                return "";
        }
    },
    getHroInterviewType: function (value){
        switch (value){
            case 1:
                return "现场面试";
            case 2:
                return "视频面试";
            case 3:
                return "电话面试";
            default:
                return "";
        }
    },
    getHroSalaryType: function (value){
        switch (value){
            case 1:
                return "元/月/人";
            case 2:
                return "元/天/人";
            case 3:
                return "元/小时/人";
            case 4:
                return "元/一次性";
            default:
                return "";
        }
    },
    getHroLastStageText: function (value){
        switch (value){
            case 1:
                return "推荐待审核";
            case 2:
                return "推荐";
            case 3:
            case 4:
                return "面试";
            case 5:
                return "入职";
            case 6:
                return "不合适";
            case 7:
                return "离职";
            default:
                return "";
        }
    },
    getInappropriateText: function(value,reason){
        switch (value){
            case 1:
                return "未参加面试";
            case 2:
                return "条件不匹配";
            case 3:
                return "专业知识不匹配"
            case 4:
                return "薪资要求不匹配";
            case 5:
                return "行业背景不匹配";
            case 6:
                return reason;
            default:
                return "";
        }
    },
    getLaborStatusText: function(value){
        switch (value){
            case -1:
                return "未上传";
            case 0:
                return "审核中";
            case 1:
                return "已上传"
            case 2:
                return "已驳回";
            default:
                return "";
        }
    },
    getJobStatusText: function(value){
        switch (value){
            case 0:
                return "失业";
            case 1:
                return "本地就业"
            case 2:
                return "异地就业";
            default:
                return "";
        }
    },
    getEmploymentTypeText: function(value){
        switch (value){
            case 1:
                return "外包"
            case 2:
                return "派遣";
            default:
                return "";
        }
    },
    getBillingYearsText: function(value){
        if(common.isEmpty(value)){
            return "";
        }
        var str = value.toString();

        return str.substring(0,4) + "." + str.substring(4,6);
    },
    getFileType(value){

        if(common.isEmpty(value)){
            return "";
        }

        return common.transformFileTypeBySuffix(value);
    },
    getServiceStatusTest: function(value){
        switch (value){
            case 1:
                return "服务中"
            case 0:
                return "服务终止";
            case -1:
                return "完成招聘";
            default:
                return "";
        }
    },
    getRejectReason(data){
        switch (data.rejectType){
            case 1:
                return "工作岗位，工作经验不符合应聘岗位要求"
            case 2:
                return "应聘者薪资要求太高";
            case 3:
                return "跳槽过去频繁";
            case 4:
                return "学历不达标";
            case 5:
                return "期望所在地与工作地不符合";
            case 6:
                return data.rejectReason;
            default:
                return "";
        }
    },
    getManagementMoney(item){

        if(item != null && item.laborCost && item.managementFee){

            return parseFloat(item.laborCost /100 * item.managementFee).toFixed(2);
        }

        return 0.00;

    },
    textRange (el) {
        const textContent = el
        const targetW = textContent.getBoundingClientRect().width
        const range = document.createRange()
        range.setStart(textContent, 0)
        range.setEnd(textContent, textContent.childNodes.length)
        const rangeWidth = range.getBoundingClientRect().width
        return rangeWidth > targetW
    }

}
export default filters;