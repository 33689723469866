import axios from "../axios";

// 编辑轮播图详情
export const editSlideshow = (params) => {
    return axios('post', '/api/slideshow/editSlideshow', params);
}

// 轮播图列表
export const slideshowList = (params) => {
    return axios('get', '/api/slideshow/slideshowList', params);
}

// 文章列表
export const articleList = (params) => {
    return axios('get', '/api/article/articleList', params);
}