import axios from "../axios";


export const getTaskList = (params) => {
    let response = axios('get',`/position/api/position/indGetHroList`,params);
    return response;
};

export const getMyTaskList = (params) => {
    let response = axios('get',`/position/api/position/indGetHroMyList`,params);
    return response;
};

export const heaGetMyHroList = (params) => {
    let response = axios('get',`/position/api/position/heaGetMyHroList`,params);
    return response;
};

export const claim = (params) => {
    let response = axios('post',`/position/api/position/addHroGet`,params);
    return response;
};

export const getTaskById = (params) => {
    let response = axios('get',`/position/api/position/getHro`,params);
    return response;
};

export const unClaim = (params) => {
    let response = axios('post',`/position/api/position/delHroGet`,params);
    return response;
};

// export const completeServiceChargeByDispatch = (params) => {
//     let response = axios('post',`/position/api/position/addDispatchRate`,params);
//     return response;
// };

export const getDispatchRate = (params) => {
    let response = axios('get',`/position/api/position/getDispatchRate`,params);
    return response;
};

export const getEpibolyRate = (params) => {
    let response = axios('get',`/position/api/position/getEpibolyRate`,params);
    return response;
};
//根据外包职位id查询职位的服务费设置详情
export const getHroAllDetailByHroId = (params) => {
    let response = axios('get',`/position/api/position/getHroAllDetailByHroId`,params);
    return response;
};
export const addDispatchRate = (params) => {
    let response = axios('post',`/position/api/position/addDispatchRate`,params);
    return response;
};

export const addEpibolyRate = (params) => {
    let response = axios('post',`/position/api/position/addEpibolyRate`,params);
    return response;
};

export const getUserSimpleInfoList = (params) => {
    let response = axios('get',`/auth/api/user/getUserSimpleInfoList`,params);
    return response;
};

//完成招聘
export const overPositionHro = (params) => {
    let response = axios('post',`/position/api/position/overPositionHro`,params);
    return response;
};

//重启招聘
export const restartPositionHro = (params) => {
    let response = axios('post',`/position/api/position/restartPositionHro`,params);
    return response;
};

//获取项目阶段内简历数量（历史数据）
export const getCandidateCountByStage = (params) => {
    let response = axios('get',`/project/api/project/stage/getCandidateCountByStage`,params);
    return response;
};

//获取我的人力外包派遣职位下的简历
export const getMyHroResumeList = (params) => {
    let response = axios('get',`/project/api/project/getMyHroResumeList`,params);
    return response;
};

//内服批量推荐给企业端
export const batchToEnt = (params) => {
    let response = axios('post',`/project/api/project/batchToEnt`,params);
    return response;
};

// 批量离职
export const batchSendDimission = (params) => {
    return axios('post', '/project/api/project/batchSendDimission', params);
}

//批量不合适
export const batchSendInappropriate = (params) => {
    let response = axios('post',`/project/api/project/batchSendInappropriate`,params);
    return response;
};

//批量发起面试
export const batchSendInterview = (params) => {
    let response = axios('post',`/project/api/project/batchSendInterview`,params);
    return response;
};

//批量修改面试
export const batchUpdateSendInterview = (params) => {
    let response = axios('post',`/project/api/project/batchUpdateSendInterview`,params);
    return response;
};

//批量发起入职
export const batchSendEntry = (params) => {
    let response = axios('post',`/project/api/project/batchSendEntry`,params);
    return response;
};

//批量撤销不合适
export const batchRevocationInappropriate = (params) => {
    let response = axios('post',`/project/api/project/batchRevocationInappropriate`,params);
    return response;
};


//外服查询外包派遣大厅
export const heaGetHroList = (params) => {
    let response = axios('get',`/position/api/position/heaGetHroList`,params);
    return response;
};

//外服查询外包派遣大厅
export const hroGrabSingle = (params) => {
    let response = axios('post',`/project/api/project/hroGrabSingle`,params);
    return response;
};


//批量加入到项目
export const batchJoinHroProject = (params) => {
    let response = axios('post',`/project/api/project/batchJoinHroProject`,params);
    return response;
};


//获取外包派遣分发佣金信息
export const getDistribute = (params) => {
    let response = axios('get',`/position/api/position/getDistribute`,params);
    return response;
};

//获取结算明细列表
export const getBillingDetails = (params) => {
    let response = axios('get',`/position/api/position/getBillingDetails`,params);
    return response;
};

//外服（内服顾问）获取结算明细列表
export const getBillingDetailsForHea = (params) => {
    let response = axios('get',`/position/api/position/getBillingDetailsForHea`,params);
    return response;
};



//内服认领者查询服务状态，返回外服、内服项目经理、劳务经纪人的服务信息
export const getDistributeServiceStatus = (params) => {
    let response = axios('get',`/project/api/project/getDistributeServiceStatus`,params);
    return response;
};
//内服认领者查询服务状态，返回外服、内服项目经理、劳务经纪人的服务信息
export const getDistributeServiceStatusNew = (params) => {
    let response = axios('get',`/project/api/project/getDistributeServiceStatusNew`,params);
    return response;
};

//结束对应外包派遣服务
export const endService = (params) => {
    let response = axios('post',`/project/api/project/endService`,params);
    return response;
};

//外包派遣职位修改外服项目成员
export const editHroProjectUser = (params) => {
    let response = axios('post',`/project/api/project/editHroProjectUser`,params);
    return response;
};

//获取外包派遣可结算的机构
export const getBillingHea = (params) => {
    let response = axios('get',`/project/api/project/getBillingHea`,params);
    return response;
};


//获取外包派遣可结算的机构
export const setBillingDetails = (params) => {
    let response = axios('post',`/position/api/position/setBillingDetails`,params);
    return response;
};


//内服获取某一条结算
export const getBillingDetailInfo = (params) => {
    let response = axios('get',`/position/api/position/getBillingDetailInfo`,params);
    return response;
};


//内服修改结算明细
export const updateBillingDetails = (params) => {
    let response = axios('post',`/position/api/position/updateBillingDetails`,params);
    return response;
};


//劳务经纪人获取推荐、面试、入职人数
export const getResumeNumByHroId = (params) => {
    let response = axios('post',`/project/api/project/getResumeNumByHroId`,params);
    return response;
};

//获取外包派遣分发佣金信息和抢单数量
export const getDistributeAndCount = (params) => {
    let response = axios('get',`/position/api/position/getDistributeAndCount`,params);
    return response;
};

//获取该职位的处理流程
export const getResumeProcess = (params) => {
    let response = axios('post',`/project/api/project/getResumeProcess`,params);
    return response;
};

//根据leader_id获取未分发的劳务顾问或者劳务经纪人
export const getNoDistributionLbbByLeaderId = (params) => {
    let response = axios('get',`/company/api/lbb/getNoDistributionLbbByLeaderId`,params);
    return response;
};
//分发劳务顾问/劳务经纪人
export const distributeLaborConsultants = (params) => {
    let response = axios('post',`/project/api/project/distributeLaborConsultants`,params);
    return response;
};
//内服设置外包派遣分发所有服务费
export const setAllDistribute = (params) => {
    let response = axios('post',`/position/api/position/setAllDistribute`,params);
    return response;
};
//根据外包派遣职位id查询职位的所有服务费设置详情
export const getHroAllDistributeDetailsByHroId = (params) => {
    let response = axios('get',`/position/api/position/getHroAllDistributeDetailsByHroId`,params);
    return response;
};
//内服设置和修改外包派遣外服机构分发数量
export const setHeaNum = (params) => {
    let response = axios('post',`/position/api/position/setHeaNum`,params);
    return response;
};
//修改可接单外服机构数量回显接口
export const getHeaNum = (params) => {
    let response = axios('get',`/position/api/position/getHeaNum`,params);
    return response;
};
//外包派遣职位查询项目成员
export const getHeaUserInfoEdit = (params) => {
    let response = axios('get',`/project/api/project/getHeaUserInfoEdit`,params);
    return response;
};
//外包派遣职位查询项目经理和项目成员详情
export const getHeaUserInfoDetails = (params) => {
    let response = axios('get',`/project/api/project/getHeaUserInfoDetails`,params);
    return response;
};
//根据leaderId查询接单的劳务经纪人信息
export const getLbbInfoByLeaderIdService = (params) => {
    let response = axios('get',`/company/api/lbb/getLbbInfoByLeaderIdService`,params);
    return response;
};
//跨节点合作拒单
export const cross_ind_refuse = (params) => {
    let response = axios('post',`/position/api/position/cross_ind_refuse`,params);
    return response;
};
//选择节点后发起跨节点合作
export const cross_ind_cooperation = (params) => {
    let response = axios('post',`/position/api/position/cross_ind_cooperation`,params);
    return response;
};
//是否展示跨节点合作详情,0不展示，1展示
export const show_cross_ind = (params) => {
    let response = axios('get',`/position/api/position/show_cross_ind`,params);
    return response;
};
//跨节点合作详情列表
export const get_cross_ind_info = (params) => {
    let response = axios('get',`/position/api/position/get_cross_ind_info`,params);
    return response;
};
//根据indCode获取未分发的合作社
export const getUndistributedCooperationByIndCode = (params) => {
    let response = axios('get',`/company/api/lbb/getUndistributedCooperationByIndCode`,params);
    return response;
};
//分发合作社
export const distributeCooperatives = (params) => {
    let response = axios('POST',`/project/api/project/distributeCooperatives`,params);
    return response;
};
//根据合作社Code查询接单的劳务经纪人信息
export const getLbbInfoByCooCodeService = (params) => {
    let response = axios('get',`/company/api/lbb/getLbbInfoByCooCodeService`,params);
    return response;
};
//设置急招
export const setUrgent = (params) => {
    let response = axios('POST',`/position/api/community/position/setUrgent`,params);
    return response;
};
//设置急招
export const outsourcingDispatchSetUrgent = (params) => {
    let response = axios('POST',`/position/api/position/setUrgent`,params);
    return response;
};
//pc职位分享接口
export const share_position = (params) => {
    let response = axios('get',`/position/api/position/share_position`,params);
    return response;
};
//pc分享外包/派遣职位二维码
export const createShareHroPositionQr = (params) => {
    let response = axios('get',`/auth/api/auth/createShareHroPositionQr`,params);
    return response;
};
//外包派遣的修改面试状态
export const updateHroSmsStatus = (params) => {
    let response = axios('POST',`/project/api/project/updateHroSmsStatus`,params);
    return response;
};
//社区职位的修改面试状态
export const updateCommunitySmsStatus = (params) => {
    let response = axios('POST',`/project/api/community/project/updateCommunitySmsStatus`,params);
    return response;
};
//（劳务派遣）重发短信使用
export const send_hro_interview_sms = (params) => {
    let response = axios('get',`/project/api/project/send_hro_interview_sms`,params);
    return response;
};
//（企业直招）重发短信使用
export const send_community_interview_sms = (params) => {
    let response = axios('get',`/project/api/community/project/send_community_interview_sms`,params);
    return response;
};