<template>
  <router-view/>
</template>
<script>

</script>

<style lang="scss">
  .ql-align-center{
    text-align: center !important;
  }
  .ql-size-huge {
    font-size: 2.5em;
  }
  blockquote {
    display: block;
    border-left: 8px solid #d0e5f2;
    padding: 5px 10px;
    margin: 10px 0;
    line-height: 1.4;
    font-size: 100%;
    background-color: #f1f1f1;
  }
  .el-message__icon {
    font-size: 16px !important;
  }
</style>
